import '~/components/shared/tracking/globalTracking';
import '~/components/shared/overflowExpandableController';
import '~/components/shared/globalFooter';
import '~/components/shared/navigation';

const currentPage = document.body.id;
const loadConsumer = [
  'b2c-blog-show',
  'b2c-health_library-show',
  'b2c-blog-by_category',
  'b2c-blog-search',
];

if (currentPage === 'b2c-blog-index' || currentPage === 'b2c-blog-thank_you') {
  import('~/components/consumer/modules/blog-index');
} else if (currentPage === 'b2c-blog-show') {
  import('~/components/consumer/modules/blog-show');
} else if (currentPage === 'b2c-pages-press') {
  import('~/components/b2c/press');
} else if (currentPage === 'b2c-pages-app_download') {
  import('~/components/b2c/appDownload');
} else if (currentPage === 'b2c-groups-show') {
  import('~/components/b2c/groups/groups');
} else if (currentPage === 'b2c-marketplace-root-service_line_landing_pages-show') {
  import('~/components/b2c/directory/serviceLineLandingPage');
} else if (currentPage === 'b2b-reviews-index') {
  import('~/components/b2b/reviewsLandingPage');
}


if (loadConsumer.includes(currentPage)) {
  import('~/components/b2c/blogQuizlet');
  import('~/components/consumer/modules/consumer');
}

if (currentPage === 'b2c-authors-profile' || currentPage === 'b2c-blog-show') {
  import('~/components/b2c/authorProfile');
}

if (currentPage === 'b2c-blog-show' || currentPage === 'b2c-health_library-show') {
  import('~/components/b2c/blog/blogExpandableContentController');
}

if (currentPage === 'b2c-marketplace-city_landing_pages-san_antonio_landing_page') {
  import('~/components/b2c/cityLandingPage');
  import('~/components/b2c/directory/bookableTimes');
  import('~/components/b2c/directory/nonApiBookableTimes');
  import('~/components/shared/controllers/expansionController');
  import('~/components/shared/controllers/autocompleteController');
  import('~/components/shared/controllers/slideOutController');
}

if (currentPage === 'b2c-groups-index') {
  import('~/components/b2c/bestPage');
  import('~/components/shared/controllers/expansionController');
  import('~/components/shared/controllers/showMoreController');
}


